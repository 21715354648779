import request from "@/axios/request";

export default {


     getSystemInfo:()=>{
         return request.getRequest('/sys/public/get')
     }





}