export default {
    namespaced:true,
    actions : {
        save_edit_blog(context,id){
            context.commit('SAVE_EDIT_BLOG',id)
        },
        remove_blog_list_item(context,value){
            context.commit('REMOVE_BLOG_LIST_ITEM',value)
        },
        save_blog_filter(context,value){
            context.commit('SAVE_BLOG_FILTER',value)
        },
        reset_filter(context){
            context.commit('RESET_FILTER')
        },
        save_tag(context,value){
            context.commit('SAVE_TAG',value)
        },
        save_cate(context,value){
            context.commit('SAVE_CATE',value)
        },
        save_recommend_list(context,value){

            context.commit('SAVE_RECOMMEND_LIST',value)
        },
        save_official_list(context,value){
            context.commit('SAVE_OFFICIAL_LIST',value)
        },
        save_blog_list(context,value){
            context.commit('SAVE_BLOG_LIST',value)
        },
        save_blog_list_info(context,value){
            context.commit('SAVE_BLOG_LIST_INFO',value)
        },
        unshift_blog_list(context,value){
            context.commit('UNSHIFT_BLOG_LIST',value)
        },
        save_blog_file_list(context,value){
            context.commit('SAVE_BLOG_FILE_LIST',value)
        }
    },
    mutations : {
        REMOVE_BLOG_LIST_ITEM(state,id){
            for(let i=0;i<state.blogList.length;i++){
                if(state.blogList[i].id === id){
                    state.blogList.splice(i,1)
                    state.blogListInfo.totalItem--
                    break;
                }
            }
        },
        SAVE_EDIT_BLOG(state,value){
            state.editBlog = value
            sessionStorage.setItem("blog_editBlog", JSON.stringify(value))
        },
        SAVE_BLOG_FILTER(state,value){
            state.blogFilter = value
        },
        SAVE_TAG_LIST(state,value){
            state.tagList = value
            sessionStorage.setItem("blog_tagList", JSON.stringify(value))
        },
        SAVE_TAG(state,value){
            let tags =  state.tagList
            let tag = {blogNum:0,...value}
            tags.push(tag)
            sessionStorage.setItem("blog_tagList", JSON.stringify(tags))
        },
        SAVE_CATE_LIST(state,value){
            state.cateList = value
            sessionStorage.setItem("blog_cateList", JSON.stringify(value))
        },
        SAVE_CATE(state,value){
            let cateList =  state.cateList
            let cate = {blogNum:0,...value}
            cateList.push(cate)
            sessionStorage.setItem("blog_cateList", JSON.stringify(cateList))
        },
        SAVE_RECOMMEND_LIST(state,value){
            state.recommendList = value
        },
        SAVE_OFFICIAL_LIST(state,value){
            state.officialList = value
        },
        SAVE_BLOG_LIST(state,value){
            state.blogList = value
        },
        SAVE_BLOG_LIST_INFO(state,value){
            state.blogListInfo = value
        },
        SAVE_COLLECT_LIST(state,value){
            state.blogCollectList = value
        },
        UNSHIFT_BLOG_LIST(state,value){
            for(let i=0;i<state.blogList.length;i++){
                if(state.blogList[i].id === value.id){
                    state.blogList[i] = {...state.blogList[i],...value}
                    return;
                }
            }
            state.blogList.unshift(value)
        },
        RESET_FILTER(state){
            state.blogFilter={cateId:null, tags:[], tagIds:[], search:null, sortType:null, isMyLove:null, year:null, month:null}
        },
        SAVE_BLOG_FILE_LIST(state,value){
            state.blogFileList = value
        }
    },
    state:{
        editBlog:JSON.parse(sessionStorage.getItem("blog_editBlog"))||{},
        blogFilter:{
            cateId:null,
            collectId:null,
            tags:[],
            tagIds:[],
            search:null,
            sortType:null,
            isMyLove:null,
            year:null,
            month:null
        },
        tagList:JSON.parse(sessionStorage.getItem("blog_tagList"))||[],
        cateList:JSON.parse(sessionStorage.getItem("blog_cateList"))||[],
        officialList:[],
        recommendList:[],
        blogFileList:[],
        blogCollectList:[],
        blogList:[],
        blogListInfo:{
            totalItem:0,
            totalPage:0
        }
    }
}
