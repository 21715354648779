export default {
    namespaced:true,
    actions : {
        submit_menu(context,value){
            context.commit('SUBMIT_MENU',value)
        },
    },
    mutations : {
        SUBMIT_MENU(state,value){
            state.currentMenu = value
        },

    },
    state:{
        currentMenu:'/',
    }
}
