import axios from "axios";


export default {
    namespaced:true,
    actions : {
        submit_comment(context,value){
            context.commit('SUBMIT_COMMENT',value)
        },
        update_list(context,list){
            context.commit('SUBMIT_COMMENT',list)
        }
    },
    mutations : {
        SUBMIT_COMMENT(state,value){
            state.submitParent = value
        },
        UPDATE_LIST(state,list){
            state.commentList = list
        }
    },
    state:{
        submitParent:false,
        commentList:[]
    }
}
