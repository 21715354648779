
export default {
        namespaced:true,
        actions : {
            save_token(context,value){
                context.commit('SAVE_TOKEN',value)
            },
            save_user(context,value){
                context.commit('SAVE_USER',value)
            },
            save_weather(context,value){
                context.commit('SAVE_WEATHER',value)
            },
            save_remember(context,value){
                context.commit('SAVE_REMEMBER',value)
            },
            save_last_get_weather_date(context){
                context.commit('SAVE_LAST_GET_WEATHER_DATE')
            },
            save_sys_config(context,value){
                context.commit('SAVE_SYS_CONFIG',value)
            },
            save_sys_config_background(context,value){
                context.commit('SAVE_SYS_CONFIG_BACK',value)
            },
            save_sys_back_path(context,value){
                context.commit('SAVE_SYS_BACK_PATH',value)
            },
            save_last_watcher_time(context,value){
                context.commit('SAVE_LAST_WATCHER_TIME',value)
            }
        },
        mutations : {
            SAVE_LAST_WATCHER_TIME(state,value){
                state.lastWatcherTime = value
            },
            SAVE_TOKEN(state,value){
                state.token = value
                localStorage.setItem("system_token", value)
            },
            SAVE_USER(state,value){
                value.lastLoadTime = new Date()
                state.user = value
                sessionStorage.setItem("system_user", JSON.stringify(value))
            },
            SAVE_WEATHER(state,value){
                state.weather = value
                sessionStorage.setItem("system_weather", JSON.stringify(value))
            },
            SAVE_REMEMBER(state,value){
                state.remember = value
                localStorage.setItem("remember_me_token", value)
            },
            SAVE_LAST_GET_WEATHER_DATE(state){
                state.lastGetWeatherDate = new Date()
            },
            SAVE_SYS_CONFIG(state,value){
                if(value){
                    state.sysConfig = value
                    sessionStorage.setItem("system_sysConfig",JSON.stringify(value))
                }
            },
            SAVE_SYS_CONFIG_BACK(state,value){
                if(state.sysConfig){
                    state.sysConfig.background = value
                    sessionStorage.setItem("system_sysConfig",JSON.stringify(value))
                }
            },
            SAVE_SYS_BACK_PATH(state,value){
                state.backPath = value
                sessionStorage.setItem("system_back_path", value)
            }
        },
        state:{
                token:localStorage.getItem("system_token") || "",
                user:JSON.parse(sessionStorage.getItem("system_user")) ||{},
                weather:JSON.parse(sessionStorage.getItem("system_weather")) ||{},
                remember:localStorage.getItem("remember_me_token")||"",
                lastGetWeatherDate:null,
                sysConfig:JSON.parse(sessionStorage.getItem("system_sysConfig")) ||{},
                backPath:sessionStorage.getItem("system_back_path") || null,
                lastWatcherTime:null
              }
    }
