import Vue from "vue";
export default {
    namespaced:true,
    actions : {
        save_plan_form(context,value){
            context.commit('SAVE_PLAN_FORM',value)
        },
        save_plan_form_time(context,star,end){
            context.commit('SAVE_PLAN_FORM',value)
        }
    },
    mutations : {
        SAVE_PLAN_FORM(state,value){
            state.planForm = value
        },
        save_plan_form_time(state,star,end){
            if(state.planForm.starTime){
                state.planForm.starTime = star
            }else {
                Vue.set(state.planForm,'starTime',star)
            }
            if(state.planForm.starTime){
                state.planForm.endTime = end
            }else {
                Vue.set(state.planForm,'endTime',end)
            }
        }
    },
    state:{
       planForm:{}
    }
}
