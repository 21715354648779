import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "@/store";

const Login = ()=>import("@/components/LoginPage");
const RegistPage = ()=>import("@/components/RegistPage");
const ForgetPage = ()=>import("@/components/ForgetPage");
const PHome = ()=>import("@/components/PHomePage");
const Error404 = ()=>import("@/components/Error404");
const UserListPage = ()=>import("@/components/UserListPage");
const BlogDetail = ()=>import("@/components/BlogDetailPage");
const BlogListPage = ()=>import("@/components/BlogListPage");
const BlogEditPage = ()=>import("@/components/BlogEditPage");
const PlanListPage = ()=>import("@/components/PlanListPage");
const FriendLinks = ()=>import("@/components/FriendLinks");
const DynamicPage = ()=>import("@/components/DynamicPage")
const WeatherPage = ()=>import("@/components/WeatherPage")
const AboutPage = ()=>import("@/components/AboutPage")
const UserInfoPage = ()=>import("@/components/UserInfoPage")
const SettingPage = ()=>import("@/components/SettingPage")
const SettingMenu = ()=>import("@/components/template/Setting/SettingMenu")
const RoleManager = ()=>import("@/components/template/Setting/RoleManager")
const CategoriesManager = ()=>import("@/components/template/Setting/CategoriesManager")
const TagManager = ()=>import("@/components/template/Setting/TagManager")
const IntoManager = ()=>import("@/components/template/Setting/IntoManager")
const FriendLinkManager = ()=>import("@/components/template/Setting/FriendLinkManager")
const BlogManager = ()=>import("@/components/template/Setting/BlogManager")
const MainBackGroundManager = ()=>import("@/components/template/Setting/MainBackGroundManager")
const NewHomePage = ()=>import("@/components/NewHomePage")
const CardPage = ()=>import("@/components/CardPage")
const PtChatPage = ()=>import("@/components/PtChatPage")
import {message} from "ant-design-vue";

Vue.use(VueRouter)

const routes = [

  {
    path: '/',
    redirect:'/home',
    name: 'NewHomePage',
    component:NewHomePage,
    meta:{title:'PT的小破站', live:true},
    children:[
      {
        path:'/home',
        name:'PHome',
        component:PHome,
        meta:{title:'PT的小破站', live:true}
      },
      {
        path:'/404',
        name:'Error404',
        component:Error404,
        meta:{title:'404', live:true}
      },
      {
        path:'/about',
        name:'AboutPage',
        component:AboutPage,
        meta:{title:'关于SQDPT', live:true}
      },
      {
        path:'/card',
        component:CardPage,
        meta:{title:'PT卡片', live:true ,auth:true}
      },
      {
        path:'/setting',
        name:'SettingPage',
        component:SettingPage,
        children:[
          {
            path:'/setting/sys/menu',
            component:SettingMenu,
            meta:{title:'菜单管理', live:true}
          },
          {
            path:'/setting/sys/into',
            component:IntoManager,
            meta:{title:'入口管理', live:true}
          },
          {
            path:'/setting/sys/role',
            component:RoleManager,
            meta:{title:'权限管理', live:true}
          },
          {
            path:'/setting/model/link',
            component:FriendLinkManager,
            meta:{title:'友链管理', live:true}
          },
          {
            path:'/setting/model/blog',
            component:BlogManager,
            meta:{title:'博客管理', live:true}
          },
          {
            path:'/setting/sys/categories',
            component:CategoriesManager,
            meta:{title:'分类管理', live:true}
          },
          {
            path:'/setting/sys/tag',
            component:TagManager,
            meta:{title:'标签管理', live:true}
          },
          {
            path:'/setting/background/main',
            component:MainBackGroundManager,
            meta:{title:'背景管理', live:true}
          },
        ],
        meta:{title:'PT设置', live:true,auth:true}
      },
      {
        path:'/blogList',
        name:'BlogList',
        component:BlogListPage,
        meta:{title:'PT博客', live:true}
      },
      {
        path:'/blog',
        name:'BlogDetail',
        component:BlogDetail,
        meta:{title:'PT博客详细', live:true}
      },
      {
        path:'/blogEdit',
        name:'BlogEdit',
        component:BlogEditPage,
        meta:{title:'编写PT博客', live:true,auth:true}
      },
      {
        path:'/userInfo',
        name:'UserInfoPage',
        component:UserInfoPage,
        meta:{title:'PT用户详细', live:true},
        props($route){
          return{
            userId:$route.query.id,
          }
        }
      },
      {
        path:'/userList',
        name:'UserList',
        component:UserListPage,
        meta:{title:'PT档案库', live:true}
      },
      {
        path:'/friendLinks',
        name:'FriendLinks',
        component:FriendLinks,
        meta:{title:'PT友链', live:true}
      },
      {
        path:'/planList',
        name:'PlanListPage',
        component:PlanListPage,
        meta:{title:'PT计划', live:true,auth:true}
      },
      {
        path:'/dynamicList',
        name:'DynamicPage',
        component:DynamicPage,
        meta:{title:'PT动态', live:true}
      },
      {
        path:'/weather',
        name:'WeatherPage',
        component:WeatherPage,
        meta:{title:'PT天气', live:true}
      },
      {
        path:'/chat',
        name:'PtChatPage',
        component:PtChatPage,
        meta:{title:'PT聊聊', live:true,auth:true}
      }
    ]
  },
  {
    path:'/login',
    name:'Login',
    component:Login,
    meta:{title:'登录', live:true}
  },
  {
    path:'/regist',
    name:'Regist',
    component:RegistPage,
    meta:{title:'注册', live:true}
  },
  {
    path:'/forget',
    name:'Forget',
    component:ForgetPage,
    meta:{title:'忘记密码', live:true}
  },
]

const router = new VueRouter({
  //mode: 'history',
  base: process.env.BASE_URL,
  routes
})

//全局前置路由守卫
//to为去的route，from为来的route
router.beforeEach((to,from,next)=>{
  store.dispatch('system/save_sys_back_path',from.path)
  if(to.path==='/logout'){
    store.dispatch('system/save_token','')
    store.dispatch('system/save_user',{})
    sessionStorage.clear()
    localStorage.clear()
    next('/login')
  }else if(to.meta.live !== true){ //路由是否存在
    next('/404')
  }else if(to.meta.auth){ //路由是否需要权限
    if(store.state.system.token){
      next()
    }else {
      message.error("该功能需先登录")
    }
  }else {
    next()
  }
})

//后置路由守卫
router.afterEach((to,from)=>{
  if(to.meta.title){
    document.title = to.meta.title
  }else{
    document.title = 'PT小破站'
  }
})






export default router
