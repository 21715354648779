<template>
  <div >
    <a-spin :spinning="refreshing">
      <div class="flex-between flex-row flex-align-center">
        <div style="width: 130px">
          <a-tooltip title="上一层文件夹">
            <a-button style="margin:0 5px 5px 5px" shape="circle"  icon="left" />
          </a-tooltip>
          <a-tooltip title="下一层文件夹">
            <a-button style="margin:0 5px 5px 5px" shape="circle"   icon="right" />
          </a-tooltip>
          <a-tooltip title="刷新">
            <a-button  @click="getPanFile" style="margin:0 5px 5px 5px" shape="circle"  icon="redo" />
          </a-tooltip>
        </div>
        <div class="flex-between flex-align-center" style="flex:1;padding: 5px;background-color: #f1f3f4;border-radius: 20px">
        <span  style="padding-left: 5px;flex: 1;max-width: 100%;min-width: 200px">
           <a-input v-if="editProfix" :value="prefix" autoFocus></a-input>
           <span  v-else> {{prefix }}</span>
        </span>
          <div style="width: 130px">
            <a-tooltip title="修改文件夹">
              <a-button style="margin:0 5px 5px 5px" shape="circle"  @click="editProfix = !editProfix" icon="edit" />
            </a-tooltip>
            <a-tooltip title="添加文件夹">
              <a-button style="margin:0 5px 5px 5px" shape="circle"  @click="editProfix = !editProfix" icon="folder-add" />
            </a-tooltip>
            <a-tooltip title="添加文件">
              <a-button @click="showUpload = !showUpload" shape="circle"  style="margin:0 5px 5px 5px"   icon="file-add" />
            </a-tooltip>
          </div>
        </div>
      </div>
      <div v-show="showUpload">
        <uploader :options="options"
                  class="pt-uploader"
                  :auto-start="false"
                  @file-added="onFileAdded"
                  @file-success="onFileSuccess"
                  @file-progress="onFileProgress"
                  @file-error="onFileError"
                  @files-submitted="onFilesSubmitted"
                  @file-retry="fileRetry"
                  :file-status-text="fileStatusText">
          <template v-slot:default="scope">
            <uploader-unsupport></uploader-unsupport>
            <uploader-drop>
              <p>拖放文件虚线框内，或者点击按钮上传文件</p>
              <uploader-btn >选择文件</uploader-btn>
            </uploader-drop>
            <uploader-list></uploader-list>
          </template>
        </uploader>
      </div>
      <a-tabs default-active-key="1" @change="callback">
        <a-tab-pane key="1" tab="文件列表">
          <div>
            <pan-item v-for="item in panItemList" :key="item.id" :item="item" :delete-file="deleteFile"></pan-item>
            <a-pagination v-model="fileSearchVO.current" :total="fileSearchVO.total"  :hideOnSinglePage="false" showLessItems />
          </div>
        </a-tab-pane>

          <a-tab-pane key="2" force-render>
             <span slot="tab">
                 下载列表<a-badge :count="downloadItemList.length" :overflow-count="99"></a-badge>
              </span>
            <pan-item v-for="item in downloadItemList" :key="item.id" :item="item" :delete-file="deleteFile"></pan-item>
          </a-tab-pane>
      </a-tabs>
    </a-spin>
<!--   <a-list item-layout="horizontal" :data-source="panItemList">
      <pan-item slot="renderItem"
                slot-scope="item, index"
                :item="item"
                :delete-file="deleteFile"
      ></pan-item>
    </a-list>-->
  </div>
</template>
<script>
//import SparkMd5 from "spark-md5"
import SvgIcon from "@/components/template/common/SvgIcon";
import MyAvatar from "@/components/template/common/MyAvatar";
import PanItem from "@/components/template/Pan/PanItem";
import fileApi from "@/axios/api/fileApi";
import {Badge} from "ant-design-vue"
export default {
  name: "PanPage",
  components: {PanItem, MyAvatar, SvgIcon,'ABadge':Badge},
  created() {
    this.getPanFile()
  },
  data() {
    return {
      refreshing:false,
      editProfix:false,
      prefix:'',
      showUpload:false,
      //target: 'http://localhost:8082/file/user/upload/append',
      options: {
        //https://github.com/simple-uploader/Uploader/blob/develop/README_zh-CN.md#%E4%BA%8B%E4%BB%B6
        //https://github.com/simple-uploader/vue-uploader/blob/master/README_zh-CN.md
        //目标上传 URL，可以是字符串也可以是函数，如果是函数的话，则会传入 Uploader.File 实例、当前块 Uploader.Chunk 以及是否是测试模式，默认值为 '/'。
        target: function (file,chunk,test){
          let isChunk =  file.isChunk
          if(test){
            //通过md5值判断单个文件，通过uploadId和offset判断chunk
            return fileApi.getUploadCheckUrl()
          }else if(isChunk){
            //如果是chunk返回分片接口
            //  this.query = {url: file.pathList[chunk.offset] , "uploadId" : file.uploadId }
              return fileApi.getUploadChunkUrl()
          }else {
            //如果是单个文件，则返回单个文件的接口
              return fileApi.getUploadSingleUrl()
          }
        },
        //是否强制所有的块都是小于等于 chunkSize 的值。默认是 false。
        chunkSize: 5 * 1024 * 1024 ,
        forceChunkSize: true,
        //上传文件时文件的参数名，默认file
        fileParameterName: 'file',
        //最大自动失败重试上传次数，值可以是任意正整数，如果是 undefined 则代表无限次，默认 0。
        maxChunkRetries : 3,
        testChunks: true, //是否测试每个块是否在服务端已经上传了，主要用来实现秒传、跨浏览器上传等
       // testMethod: '' , //测试的时候使用的 HTTP 方法，可以是字符串或者函数，如果是函数的话，则会传入 Uploader.File 实例、当前块 Uploader.Chunk，默认 GET
        headers: {
          'plife-access-token':this.$store.state.system.token,
        },
        query:function (file,chunk){
          if(file.pathList){
            let offset =  chunk.offset
            return { url: file.pathList[offset] , "uploadId" : file.uploadId }
          }
        },
        // 当上传的时候所使用的是方式，可选 multipart、octet，默认 multipart，参考 multipart vs octet。
        // MiniO 的分片不能使用表单
       // method:'octet',
       // uploadMethod:'PUT',
        parseTimeRemaining: function (timeRemaining, parsedTimeRemaining) {
          return parsedTimeRemaining
              .replace(/\syears?/, '年')
              .replace(/\days?/, '天')
              .replace(/\shours?/, '小时')
              .replace(/\sminutes?/, '分钟')
              .replace(/\sseconds?/, '秒')
        },
        //可选的函数用于根据 XHR 响应内容检测每个块是否上传成功了，传入的参数是：Uploader.Chunk 实例以及请求响应信息。这样就没必要上传（测试）所有的块了
        checkChunkUploadedByResponse :(chunk, message)=>{
          let result = JSON.parse(message)
          console.log("checkChunkUploadedByResponse",result)
          if(result.data.uploaded){
             return true
          }
          let list =  result.data.uploadedIndexList
          return (list || []).indexOf(chunk.offset + 1) >= 0
        },
        categoryMap:{
          image: ['gif', 'jpg', 'jpeg', 'png', 'bmp', 'webp'],
          video: ['mp4', 'm3u8', 'rmvb', 'avi', 'swf', '3gp', 'mkv', 'flv'],
          audio: ['mp3', 'wav', 'wma', 'ogg', 'aac', 'flac'],
          document: ['doc', 'txt', 'docx', 'pages', 'epub', 'pdf', 'numbers', 'csv', 'xls', 'xlsx', 'keynote', 'ppt', 'pptx']
        }

      },
      fileStatusText:{
        success: '成功了',
        error: '失败了',
        uploading: '上传中',
        paused: '暂停中',
        waiting: '等待中'
      },
      attrs: {
        // 接受的文件类型，形如 这里我封装了一下
        accept:{

        }
      },
      panelShow: false,   //选择文件后，展示上传panel
      fileSearchVO:{
        current:1,
        total:0,
      },
      panItemList:[],
      downloadItemList:[],
      uploadFileList:[]
    }
  },
  methods:{
    // 上传完成
    complete() {
      console.log('complete', arguments)

    },
    onFileAdded(file){
    //  this.getUploadChunkUrl(file)
      /* this.md5WithChunk(file,5 * 1024 * 1024).then(resp=>{
         console.log("onFileAdded",resp)
          file.identifier = resp
       })*/
    },
    getUploadChunkUrl(file){
      let size = file.chunks.length
      //如果分片数量大于1，则调用分片初始化接口，否则直接上传
      if(size > 1){
        fileApi.initUpload({filename: file.name, totalChunks: size , identifier: file.identifier}).then(resp=>{
          file.pathList = resp.data.data.pathList
          file.uploadId = resp.data.data.uploadId
          file.isChunk = true
          file.resume()
        })
      }else {
        file.isChunk = false
        file.resume()
      }
    },
    onFileSuccess(rootFile, file, message, chunk){
      console.log("onFileSuccess",file,message,chunk)
      let length =   file.chunks.length
      if(length > 1){
        let fileVO = {
          filename:file.name,
          // folderName:'',
          identifier:file.uniqueIdentifier,
          fileType:file.fileType,
          // folderId:'',
          totalSize:file.size,
          uploadId:file.uploadId,
          totalChunks:file.chunks.length
        }
        fileApi.mergeFile(fileVO).then(resp=>{
          if(resp.data.success){
            this.$message.success('上传成功')
          }
        })
      }else {
        this.$message.success('上传成功')
      }
    },
    // 文件进度的回调
    onFileProgress(rootFile, file, chunk){
      console.log('onFileProgress',rootFile, file, chunk)
    },
    onFileError(root,file,message,chunk){
      console.log('onFileError',root,file,message,chunk)
    },
    getPanFile(){
      this.refreshing = true
      fileApi.getPanMinioFilePage(this.fileSearchVO).then(resp=>{
          if(resp.data.success){
             this.panItemList =  resp.data.data.dataList
             this.fileSearchVO.total = resp.data.data.totalItem
            this.fileSearchVO.current = resp.data.data.current
          }
        this.refreshing = false
      })
    },
    fileDownloadProgress(e,item){
        let load = e.loaded
        let total = e.total
        let progress = Math.floor(Number(load/total) *100) +'%'
        if(item.hasOwnProperty('progress')){
          item.progress = progress
        }else{
          this.$set(item,'progress',progress)
        }
    },
    downloadFile(item){
      fileApi.downloadFile({id: item.id},'blob',(e)=>{
          this.fileDownloadProgress(e,item)
        }
      ).then(resp=>{
        console.log("downloadFile",resp)
        /*if(resp.status === 200){
          //创建 blob 对象
          const blob = new Blob([resp.data]);
          //创建一个唯一的 URL
          const url = URL.createObjectURL(blob);
          //创建一个a标签
          const a = document.createElement("a");
          //将下载地址赋值给herf属性
          a.href = url;
          //设置下载文件的名字
          let file_all =  resp.headers['content-disposition'].split('filename=')[1]
          let fileName = file_all.split("\"")[1].split("\"")[0];
          a.download = fileName;
          a.style.display = 'none';// 将a标签设置为隐藏
          a.click();
          URL.revokeObjectURL(a.href) // 下载完成释放URL 对象
          this.$message.success("下载成功")
        }else {
           this.$message.error("下载错误")
        }*/
      })
    },
    deleteFile(id){
      fileApi.deleteFile({id: id}).then(resp=>{
        if(resp.data.success){
            for(let i=0;i<this.panItemList.length;i++){
                if(this.panItemList[i].id === id){
                  this.panItemList.splice(i,1)
                  break;
                }
            }
        }
     })
    },
    //和 filesAdded 类似，但是是文件已经加入到上传列表中，一般用来开始整个的上传。
    onFilesSubmitted(files, fileList, event){
       //获取URL 和 uploadId
       for (let i = 0; i < files.length ; i++) {
         this.getUploadChunkUrl(files[i])
       }
    },
    md5WithChunk(file, chunkSize, progressCallback = console.log) {
      return new Promise((resolve, reject) => {
        const blobSlice =
            File.prototype.slice ||
            File.prototype.mozSlice ||
            File.prototype.webkitSlice
        let chunks = Math.ceil(file.size / chunkSize)
        let currentChunk = 0
        let spark = new SparkMD5.ArrayBuffer()
        let fileReader = new FileReader()
        fileReader.onerror = reject
        fileReader.onload = (e) => {
          progressCallback(currentChunk / chunks)
          spark.append(e.target.result)
          currentChunk++
          if (currentChunk < chunks) {
            let start = currentChunk * chunkSize
            let end = start + chunkSize >= file.size ? file.size : start + chunkSize
            fileReader.readAsArrayBuffer(blobSlice.call(file, start, end))
          } else {
            resolve(spark.end())
          }
        }
        let start = currentChunk * chunkSize
        let end = start + chunkSize >= file.size ? file.size : start + chunkSize
        fileReader.readAsArrayBuffer(blobSlice.call(file, start, end))
      })
    },
    //文件重试上传事件
    fileRetry(rootFile, file, chunk){
        /*if(! file.pathList){
          this.getUploadChunkUrl(file)
        }*/
    }
  }

}
</script>

<style scoped>
.pt-uploader {
  margin: 0 auto;
  font-size: 12px;

}
.pt-uploader .uploader-btn {
  margin-right: 4px;
}
.pt-uploader .uploader-list {
  max-height: 440px;
  overflow: auto;
  overflow-x: hidden;
  overflow-y: auto;
}
</style>