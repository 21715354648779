export default {
    namespaced:true,
    actions : {
        save_menu_list(context,value){
            context.commit('SAVE_MENU_LIST',value)
        }
    },
    mutations : {
        SAVE_MENU_LIST(state,value){
            state.menuList = value
            sessionStorage.setItem("setting_menuList", JSON.stringify(value))
        }
    },
    state:{
        menuList:JSON.parse(sessionStorage.getItem("setting_menuList"))||[]
    }
}
