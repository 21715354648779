import request from "@/axios/request";

export default {

    addWatcher:()=>{
        return request.getRequest('/watcher/public/save')
    },
    getWatcher:()=>{
        return request.getRequest('/watcher/public/get')
    }



}