import Vue from 'vue'
import App from './App.vue'
import VueLazyload from "vue-lazyload";
import {Button,Layout,Avatar,Affix, BackTop,Card,Checkbox,Col,Row,FormModel,List,
  Menu,Input,Select,Switch,Descriptions,Tabs,Tag,Tooltip,
Modal,Popconfirm,Progress,Spin,Icon,Collapse,Steps,message,Pagination} from 'ant-design-vue'
import VueViewer from "v-viewer";
import 'viewerjs/dist/viewer.css'
import router from './router'
import '@/assets/myIcon/iconfont.js'
import '@/assets/myIcon/iconfont.css'
import store from "@/store";
import upload from "vue-simple-uploader";
import moment from "moment/moment";
import imgUitls from "@/utils/imgUitls";




Vue.config.productionTip = false


Vue.use(VueViewer)
Vue.use(upload)
Vue.use(VueLazyload,{
  dispatchEvent: true
})
Vue.use(Pagination)
Vue.use(Collapse)
Vue.use(Icon);
Vue.use(Menu);
Vue.use(Affix);
Vue.use(Avatar);
Vue.use(BackTop);
Vue.use(Button);
Vue.use(Card);
Vue.use(Checkbox);
Vue.use(Col);
Vue.use(FormModel);
Vue.use(Input);
Vue.use(Layout);
Vue.use(List);
Vue.use(Modal);
Vue.use(Popconfirm);
Vue.use(Progress);
Vue.use(Row);
Vue.use(Select);
Vue.use(Spin);
Vue.use(Steps);
Vue.use(Switch);
Vue.use(Tabs);
Vue.use(Tag);
Vue.use(Tooltip);
Vue.use(Descriptions);

Vue.prototype.$imgUtils = imgUitls
Vue.prototype.$message = message


    Date.prototype.toISOString = function (){
  return moment(this).format('YYYY-MM-DDTHH:mm:ss')
}


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')