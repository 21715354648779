<template>
  <a-menu  :mode="menuMode"     class="flex-around flex-align-center font-weight-700">
    <!--Home-->
    <a-menu-item key="home" @click="goPath(homeMenu.path)">
<!--      <img style="height: 24px" src="../../assets/logo.png" alt="logo"/>-->
      首页
<!--      <SvgIcon :name="homeMenu.name" :icon-class="homeMenu.icon"></SvgIcon>-->
    </a-menu-item>
    <a-menu-item key="blog" @click="goPath(blogMenu.path)">
      博客
<!--      <SvgIcon :name="blogMenu.name" :icon-class="blogMenu.icon"></SvgIcon>-->
    </a-menu-item>
    <a-menu-item key="dynamic" @click="goPath(dynamicMenu.path)">
      动态
<!--      <SvgIcon :name="dynamicMenu.name" :icon-class="dynamicMenu.icon"></SvgIcon>-->
    </a-menu-item>
    <!--其他菜单-->
      <a-sub-menu v-for="(menu,index) in  MenuList" :key="index">
          <span @click="goPath(menu.title.path)" slot="title" class="submenu-title-wrapper">
<!--                  <SvgIcon :name="menu.title.name" :icon-class="menu.title.icon"></SvgIcon>-->
            {{menu.title.name}}
          </span>
      <a-menu-item v-for="item in menu.items" :key="item.name" @click="goPath(item.path)">
        <SvgIcon :name="item.name" :icon-class="item.icon"></SvgIcon>
      </a-menu-item>
    </a-sub-menu>
    <!--用户菜单-->
<!--    <a-sub-menu class="user-menu">
        <span slot="title" >
           <span class="user-name-text"  v-text="myName" style="margin-right: 5px"></span>
           <my-avatar :url="myAvatar" :name="myName"></my-avatar>
        </span>
      &lt;!&ndash;详情、退出&ndash;&gt;
      <a-menu-item v-if="$store.state.system.token"  v-for="item in liveMenu" :key="item.name" @click="goPath(item.path)">
        <SvgIcon :name="item.name" :icon-class="item.icon"></SvgIcon>
      </a-menu-item>
      &lt;!&ndash;登录注册&ndash;&gt;
      <a-menu-item v-if="!$store.state.system.token" v-for="item in unLiveMenu" :key="item.name" @click="goPath(item.path)">
        <SvgIcon :name="item.name" :icon-class="item.icon"></SvgIcon>
      </a-menu-item>
    </a-sub-menu>-->
  </a-menu>
</template>

<script>
import SvgIcon from "@/components/template/common/SvgIcon";
import MyAvatar from "@/components/template/common/MyAvatar";
import goPath from "@/mixin/goPath";
export default {
  name: "PHeader",
  props:{
    menuMode:{
      Type:String,
      default:'horizontal'
    }
  },
  components: {MyAvatar, SvgIcon},
  data(){
    return{
      MenuList:[
        {
          title:{
                name:'系统',
                icon:'#icon-jisuanjidiannaoxinxi',
                path:''
          },
          items:[
            {
              name:'用户们',
              icon:'#icon-user-36',
              path:'/userList'
            },
            {
              name:'PT友链',
              icon:'#icon-link',
              path:'/friendLinks'
            },
            {
              name:'设置',
              icon:'#icon-settings__eas',
              path:'/setting'
            },
            {
              name:'关于',
              icon:'#icon-about',
              path:'/about'
            }
          ]
        },

        {
          title:{
            name:'更多',
            icon:'#icon-gongneng1',
            path:'',
          },
          items:[

           /* {
              name:'PT聊聊',
              icon:'#icon-chat',
              path:'/chat'
            },
            {
              name:'PT商店',
              icon:'#icon-online-store',
              path:'/store'
            },*/
            {
              name:'PT卡片',
              icon:'#icon-kapian',
              path:'/card'
            },
            {
              name:'PT网盘',
              icon:'#icon-yunduan',
              path:'/pan'
            },
            {
              name:'PT计划',
              icon:'#icon-luqujihua',
              path:'/planList'
            },
            /*{
              name:'PT账单',
              icon:'#icon-zhangdan',
              path:'/billList'
            },
            {
              name:'PT投资',
              icon:'#icon-gupiao-shouyedianjitai',
              path:'/invest'
            },
            {
              name:'PT导航',
              icon:'#icon-daohang',
              path:'/navigation'
            }*/
          ]
        },
      ],
      liveMenu:[
          {
            name:'详细',
            icon:'#icon-wo',
            path:'/userInfo'
          },
          {
            name:'退出',
            icon:'#icon-tuichu',
            path:'/logout'
          }

      ],
      unLiveMenu:[
        {
          name:'登录',
          icon:'#icon-login-copy',
          path:'/login'
        },
        {
          name:'注册',
          icon:'#icon-zhuce',
          path:'/regist'
        }

      ],
      homeMenu:{
        name:"首页",
        icon:'#icon-SmartHome',
        path:'/'
      },
      blogMenu:{
        name:'博客',
        icon:'#icon-blog',
        path:'/blogList'
      },
      dynamicMenu:{
        name:'动态',
        icon:'#icon-wodedongtai',
        path:'/dynamicList'
      },
      userMenu:{
        nickname:this.myName,
        avatar:this.myAvatar
      }
    }
  },
  mixins:[goPath],
  computed:{
    myUser(){
      return this.$store.state.system.user
    },
    myName(){
      return this.myUser.nickname
    },
    myAvatar(){
      return this.myUser.avatar
    },
    myUserId(){
      return this.myUser.id
    }
  }
}
</script>
<style scoped>

/*手机*/
@media screen and (max-device-width: 992px){
  .user-menu{

  }
  .user-name-text{
    display: none;
  }
}
/*电脑*/
@media screen and (min-device-width: 993px){
  .user-menu{
    float: right;
  }
}

</style>