import {message} from "ant-design-vue";
//压缩图片
function compressImg(file,max,w){
    return new Promise((resolve,reject) => {
        // 压缩图片需要的一些元素和对象
        let reader = new FileReader(), img = new Image();
        reader.readAsDataURL(file);
        reader.onload = function (e) {
            img.src = e.target.result;
        };
        img.onload = function () {
            // 缩放图片需要的canvas
            let canvas = document.createElement('canvas');
            let context = canvas.getContext('2d');
            // 图片原始尺寸
            let originWidth = this.width;
            let originHeight = this.height;
            // 最大尺寸限制
            let maxWidth = w || 1080, maxHeight = 1080;
            // 目标尺寸,Math.round四舍五入
            let targetWidth = Math.round(originWidth * 0.85),
                targetHeight = Math.round(originHeight * 0.85);
            // 图片尺寸超过1024x1024的限制
            if (originWidth > maxWidth || originHeight > maxHeight) {
                if (originWidth / originHeight > maxWidth / maxHeight) {
                    // 更宽，按照宽度限定尺寸
                    targetWidth = maxWidth;
                    targetHeight = Math.round(maxWidth * (originHeight / originWidth));
                } else {
                    targetHeight = maxHeight;
                    targetWidth = Math.round(maxHeight * (originWidth / originHeight));
                }
            }
            // 目标尺寸,canvas对图片进行缩放
            canvas.width = targetWidth;
            canvas.height = targetHeight;
            // 清除画布
            context.clearRect(0, 0, targetWidth, targetHeight);
            // 图片压缩
            context.drawImage(img, 0, 0, targetWidth, targetHeight);
            // canvas转为blob并上传
            let base = 0.7
            let m = max || 1024 * 1024
            if(file.size < m){
                //小于1M的直接返回，如果压缩的话size反而会变大
                resolve(file)
            }else {
                canvas.toBlob( blob => {
                    let imgFile = new File([blob], file.name, {
                        type: file.type,
                    }); // 将blob对象转化为图片文件
                    imgFile.uid = file.uid
                    if(imgFile.size < m){
                        resolve(imgFile)
                    }else {
                        message.error("图片压缩后还是超过了1M")
                        reject("图片压缩后还是超过了1M")
                    }
                }, file.type, base); // file压缩的图片类型
            }

        };

    })
}

//结尾处将该方法暴露出来供外部调用
export default {
    compressImg
}