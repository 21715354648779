import request from "@/axios/request";

export default {

    getMessageMap(params){
        return request.getRequest('/chat/user/message/map', params)
    },
    readMessage(params){
        return request.getRequest('/chat/user/message/read', params)
    },
    getNotReadMessageMap(){
        return request.getRequest('/chat/user/message/map/notRead')
    },
    createMessageGroup(data){
        return request.postRequest('/chat/user/group/create',data)
    },
    getChatGroupList(){
        return request.getRequest('/chat/user/group/list')
    },
    getChatGroupInfo(params){
        return request.getRequest('/chat/user/group/info',params)
    },
    getNotReadMessageCount(){
        return request.getRequest('/chat/user/message/count/notRead')
    }


}