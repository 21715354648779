<template>
 <span >
      <img v-if="showImg" :class="size" @click="clickAvatar" :style="style" @error="imgError" v-lazy="avatar" :key="avatar" alt="图"/>
      <a-avatar v-else :size="textAvatarSize" @click="clickAvatar" :style="style">{{nameText}}</a-avatar>
 </span>
</template>

<script>

export default {
  name: "MyAvatar",
  props:{
    showNameText:{
      type:Boolean,
      default:true
    },
    size:{
      type:String,
      default(){
        return 'default'
      }
    },
    avatar:{
      type:String
    },
    clickType:{
      //-1啥都不干，0展示图片，1跳转用户详情，2跳转链接
      type:Number,
      default:0
    },
    name:{
      type:String
    },
    url:{
      type:String
    },
    userId:{
      type:Number
    }
  },
  data(){
    return{
      style:'',
      isOkImg:true,
      loadImageTime:0,
      userInfo:{}
    }
  },
  created() {
    if(this.clickType !== -1){
      this.style = 'cursor:pointer;'+this.style
    }
  },
  computed:{
    nameText(){
        if(this.name){
          this.style ='background-color: #5fb03f;'
          return this.name
        }else {
          this.style = ''
          return '未登录'
        }
    },
    showImg(){
      if(!this.avatar){
          return false
      }else {
        return  this.showNameText ? this.isOkImg :true
      }
    },
    textAvatarSize(){
      if(this.size === 'big'){
        return 64
      }
      return  this.size
    }
  },
  methods:{
    imgError(){
          this.isOkImg = false
    },
    clickAvatar(){
      if(this.clickType === 1){
        let loginUser = this.$store.state.system.user
        if(this.userId && loginUser){
          this.$router.push({
            path:'/userInfo',
            query:{
              id:this.userId
            }
          })
        }
      }
      else if(this.clickType === 0){
        this.$viewerApi({
          images:[this.avatar]
        })
      }
      else if(this.clickType === 2){
        if(this.url){
          window.open(this.url)
        }

      }
    }
  }
}
</script>

<style scoped>
.big{
  width: 64px;
  height: 64px;
  border-radius: 50%
}
.large{
  width: 40px;
  height: 40px;
  border-radius: 50%
}
.default{
  width: 32px;
  height: 32px;
  border-radius: 50%
}
.small{
  width: 24px;
  height: 24px;
  border-radius: 50%
}
</style>