import axios from "axios";
import store from "@/store";
import {message} from "ant-design-vue";

//const baseUrl = '/'
const baseUrl = 'https://sqdpt.top/api/'

const request = axios.create({
     baseURL: baseUrl,
     timeout: 5000 // 请求的超时
})

request.interceptors.request.use(function (config) {
    if(store.state.system.token){
        config.headers['plife-access-token'] = store.state.system.token
    }

   if(store.state.system.remember){
        config.headers['remember-me-token'] = store.state.system.remember
    }
    return config;
}, function (error) {
   message.error(error.message)
});

//响应拦截器
request.interceptors.response.use(resp=>{
    if(resp.data.success != null && resp.data.success ===false){
        message.error(resp.data.message)
    }else if(resp.data.message){
        message.success(resp.data.message)
    }
    let token = resp.headers['plife-access-token']
    if(token){
        store.dispatch("system/save_token",token)
    }
    let rememberMe = resp.headers['remember-me-token']
    if(rememberMe){
        store.dispatch('system/save_remember',rememberMe)
    }
    return resp;
},error=>{
   message.error(error.message);
})




const getRequest = (url,params) => request({
        url:url,
        method:'get',
        params:params
})

const getRequestWithResponseType = (url,params,responseType,progress) => request({
    url:url,
    method:'get',
    params:params,
    responseType:responseType,
    onDownloadProgress:progress,
    timeout: 1000 * 60 * 60 * 24
})





const postRequest = (url,data) => request({
    url:url,
    method:'post',
    data:data
})

const deleteRequest = (url,params) => request({
    url:url,
    method:'delete',
    params:params
})

const putRequest = (url,data) => request({
    url:url,
    method:'put',
    data:data,
})





export default {
    baseUrl,
    request,
    getRequest,
    getRequestWithResponseType,
    postRequest,
    deleteRequest,
    putRequest
}


/*Vue.use(VueAxios, axios)*/
