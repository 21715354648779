<template>
  <div >
    <svg  :class="SvgClass" >
      <use  v-bind:xlink:href="iconClass"></use>
    </svg>
    <div style="margin-top: 4px" v-if="textDown">{{name}}</div>
    <span v-else>{{name}}</span>
  </div>
</template>
<script>
export default {
  name: "SvgIcon",
  props:{
    iconClass:{
      type:String
    },
    textDown:{
      type:Boolean,
      default:false
    },
    name:{
      type:String
    },
    size:{
      type:String,
      default: 'small'
    },
    hover:{
      type:Boolean,
      default:false
    }
  },
  computed:{
    SvgClass(){
      if(this.size === 'small'){
        return 'icon '+this.hoverDiv
      }
      if(this.size === 'large'){
        return 'icon icon-large '+this.hoverDiv
      }
    },
    hoverDiv(){
      return this.hover ? 'hover-div-open' :  'hover-div-close'
    }
  }
}
</script>

<style scoped>
  .icon{
    width: 1em;
    height: 1em;
    vertical-align: -0.15em;
    fill: currentColor;
    overflow: hidden;
  }
  .icon-large{
    width: 40px!important;
    height: 40px!important;
  }
  .hover-div-open:hover{
    transform: scale(1.05);
  }
</style>