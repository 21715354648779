<template>
  <div >
    <a-row type="flex" >
      <a-col :xs="{span:24,order:1}" :sm="{span:24,order:1}" :lg="{span:7,order:1}">
        <div class="footer-info" >
          <img src="../../assets/logo_long.png" alt="logo"/>
        </div>
      </a-col>
      <a-col  :xs="{span:24,order:3}" :sm="{span:24,order:3}" :lg="{span:10,order:2}">
        <div class="footer-info">
          <span>
              Copyright © 2021-{{new Date().getFullYear()}} <a href="http://sqdpt.top">PT的小破站</a>
            </span>
          <span>
              本网站由
                  <a href="https://www.upyun.com/?utm_source=lianmeng&utm_medium=referral">
                    <img height="40px" src="@/assets/img/youPaiYunLogo.png" alt="又拍云"/>
                  </a>
                  提供CDN加速/云存储服务
            </span>
          <span>
                  <img src="@/assets/img/beian.png" alt="警徽"/>
                  <a  target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index">蜀ICP备20016740号</a>
            </span>
        </div>
      </a-col>
      <a-col :xs="{span:24,order:2}" :sm="{span:24,order:2}" :lg="{span:7,order:3}">
        <div  class="footer-info-icon">
          <div class="footer-info-row">
            <a href="https://space.bilibili.com/142777393" >
              <a-tooltip placement="top" title="关注B站">
                <svg-icon class="footer-icon" size="large" icon-class="#icon-bilibili"></svg-icon>
              </a-tooltip>
            </a>
            <a href="https://music.163.com/#/user/home?id=1327117424" >
              <a-tooltip placement="top" title="关注网易云">
                <svg-icon class="footer-icon" size="large" icon-class="#icon-wangyiyun"></svg-icon>
              </a-tooltip>
            </a>
            <a >
              <a-tooltip placement="top" title="关注抖音">
                <svg-icon class="footer-icon" size="large" icon-class="#icon-douyin"></svg-icon>
              </a-tooltip>
            </a>
          </div>
          <div class="footer-info-row ">
            <div class="qs-img">
              <img style="height: 72px" src="@/assets/qqGroup.png" alt="QQ群二维码"/>
              <div>扫码加QQ群</div>
            </div>
            <div class="qs-img">
              <img style="height: 72px" src="@/assets/img/officialAccount.jpg" alt="公众号二维码"/>
              <div>关注公众号</div>
            </div>
          </div>

        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import SvgIcon from "@/components/template/common/SvgIcon";
export default {
  name: "PFooter",
  components: {SvgIcon}
}
</script>

<style scoped>
  .footer-info{
    display: flex;
    flex-flow: column wrap;
    align-items: center;
  }
  .footer-icon{
    margin: 10px;
    cursor: pointer;
  }
  .footer-info-row{
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    align-items: center;
  }
  .qs-img{
    margin: 0 6px;
  }
  /*手机*/
  @media screen and (max-device-width: 992px){
      .footer-info-icon{
        display: flex;
        flex-flow: column wrap;
        align-items: center;
        margin: 0 0 10px 0;
      }
  }
  /*电脑*/
  @media screen and (min-device-width: 993px){
    .footer-info-icon{
      display: flex;
      flex-flow: row wrap;
      justify-content: space-around;
      align-items: center;
    }
  }

</style>