import Vue from "vue";
import websocket from "@/utils/websocket"
export default {
    namespaced:true,
    actions : {
        /**
         * 发送消息
         * @param context
         * @param message 消息对象
         */
        sendMessage(context,message){
            if(message &&  message.context && message.context.trim().length > 0){
                websocket.sendMessage(message)
                let key = message.toId + "-" + message.chatType
                message.self = true
                context.commit('addMessageToMap',[key,message])
                context.commit('topChatUserByFromId',[message.toId,message.chatType,message.toName,message.toAvatar,message.context,message.messageType])
            }
        },
        /**
         * 接收消息
         * @param context
         * @param message 消息对象
         */
        getMessage(context,message){
             if (message &&  message !== 'pong' ){
                 if(message.chatType === 0){
                     let key = message.fromId+ "-" + message.chatType
                     context.commit('addMessageToMap',[key,message])
                     context.commit('topChatUserByFromId',[message.fromId,message.chatType,message.fromName,message.fromAvatar,message.context])
                 }else {
                     let key = message.toId+ "-" + message.chatType
                     context.commit('addMessageToMap',[key,message])
                     context.commit('topChatUserByFromId',[message.toId,message.chatType,message.toName,message.toAvatar,message.context])
                 }
               /* context.commit('getMessageMutation',message)
                context.commit('getChatUserByGetMessage',message)*/
            }
        },
        /**
         * 设置未读消息总数
         * @param context
         * @param count
         */
        setNotReadMessageCount(context,count){
            context.commit('setNotReadMessageCount',count)
        },
        /**
         * 接收未读的消息记录
         * 1. 将消息记录存入消息map
         * 2. 将最旧的消息存入未读消息map
         * 3. 将最新消息置顶聊天列表
         * @param context
         * @param messageMap 未读消息map
         */
        getNotReadMessageMap(context,messageMap){
            for(let key in messageMap){
                let array = messageMap[key]
                if(array && array.length>0){
                    context.commit('addMessageListToMap',[key,array])
                    let message = array[array.length - 1]
                    context.commit("setNotReadMessageMap",[key,message.id])
                    let newMsg = array[0]
                    if(newMsg.chatType === 0){
                        context.commit('topChatUserByFromId',[newMsg.fromId,newMsg.chatType,newMsg.fromName,newMsg.fromAvatar,newMsg.context,newMsg.messageType])
                    }else {
                        context.commit('topChatUserByFromId',[newMsg.toId,newMsg.chatType,newMsg.toName,newMsg.toAvatar,newMsg.context,newMsg.messageType])
                    }
                }
            }
        },
        /**
         * 接收历史记录
         * @param context
         * @param key chatId-chatType
         * @param messageList 消息列表
         */
        getMessageList(context,[key,messageList]){
            context.commit('addMessageListToMap',[key,messageList])
        },
        /**
         * 选择用户聊天
         * @param context
         * @param chatId
         * @param chatType
         * @param chatName
         * @param chatAvatar
         */
        checkUserToChat(context,[chatId,chatType, chatName , chatAvatar]){
            context.commit('setCurrentChatUser',[chatId,chatType,chatName,chatAvatar])
            context.commit('topChatUserByFromId',[chatId,chatType,chatName,chatAvatar])
        },
        /**
         * 清空聊天用户的未读气泡
         * @param context
         * @param chatId
         * @param chatType
         */
        clearChatUserNotReadDiscount(context,[chatId,chatType]){
            context.commit('clearNotReadDiscount',[chatId,chatType])
        },
        /**
         * 清空聊天记录
         */
        clearAllMessageMap(context){
            context.commit('clearMessageMap')
        },
        setCurrentChatUser(context,[chatId,chatType, chatName , chatAvatar]){
            context.commit('setCurrentChatUser',[chatId,chatType,chatName,chatAvatar])
        }
    },
    mutations : {
        setNotReadMessageCount(state,count){
            state.notReadMessageCount = count
        },
        clearMessageMap(state){
            state.messageMap = {}
        },
        setCurrentChatUser(state,[chatId,chatType, chatName , chatAvatar]){
            state.currentChatUser = {fromId: chatId,chatType:chatType,fromName:chatName,fromAvatar:chatAvatar}
        },
        /**
         * 存储未读消息map
         * @param state
         * @param key
         * @param id message的id
         */
        setNotReadMessageMap(state,[key,id]){
            state.notReadMessageMap[key] = id
        },
        //选择一个用户聊天
        checkUserToChatMutation(state,message){
            let toId = message.toId
            //将聊天人置顶
            let chatList = state.chatList
            let old =  chatList.filter(x=>x.fromId === toId)[0]
            //移除toId,然后将新的置顶
            let newList =  chatList.filter(x=>x.fromId !== toId)
            if(old){
                newList.unshift(old)
            }else {
                let newMessage = {...message}
                newMessage.fromId = toId
                newMessage.fromName = message.toName
                newMessage.fromAvatar = message.toAvatar
                newList.unshift(newMessage)
            }
            state.chatList = newList

        },
        /**
         * 将聊天对象信息设置为已读信息
         * @param state
         * @param chatId
         * @param chatType
         */
        clearNotReadDiscount(state,[chatId,chatType]){
            let count = 0
            //清空聊天记录里的
            let key = chatId + "-" + chatType
            let mapList =  state.messageMap[key]
            if(mapList && mapList.length>0){
                mapList.forEach(x=>{
                    if(x.fromId === chatId){
                        count++
                        x.state = 1
                    }
                })
            }
            state.notReadMessageMap -= count
            //清空列表上的
            let chatList =  state.chatList
            if(chatList && chatList.length>0){
                chatList.forEach(x=>{
                    if(x.fromId  === chatId && x.chatType === chatType){
                        x.notReadCount = 0
                    }
                })
            }

           // localStorage.setItem( this.$store.state.system.user.id + '-'+"chat_chatList", JSON.stringify(state.chatList))
        },
        /**
         * 添加用户列表并且移动到最上方，并且设置未读消息个数
         * @param state
         * @param chatId 聊天人的ID
         * @param chatType 聊天的对象类型，群/人
         * @param chatName 聊天人的姓名
         * @param chatAvatar 聊天人的头像
         * @param context 消息
         * @param messageType 消息类型 0:文本 1：图片
         */
        topChatUserByFromId(state,[chatId,chatType,chatName,chatAvatar,context,messageType]){
            //获取聊天记录中未读的消息
            let array =  state.messageMap[chatId+"-"+chatType]
            let notReadCount = 0
            if(array){
                notReadCount =  array.filter(x=>x.state === 0 && x.fromId === chatId).length
            }
            let message = {
                fromId:chatId,
                fromName:chatName,
                fromAvatar:chatAvatar,
                context: messageType === 1 ? '[图片]' : context,
                chatType:chatType,
                notReadCount:notReadCount,
                messageType:messageType
            }

            let first = state.chatList[0]
            if(first && first.fromId === chatId && first.chatType === chatType){
                //如果当前就是第一个，那么直接赋值后返回
                first.notReadCount = notReadCount
                first.context = context ? context : first.context
                return
            }

            //如果没有内容，则获取历史记录里面的最新一条补上
            if(!message.context){
                let msg =  state.messageMap[chatId+"-"+chatType]
                if(msg){
                    message.context = msg.context
                }
            }
            //如果之前有数据，并且这次没有新的数据，则利用老数据
            let oldArray =  state.chatList.filter(x=>x.fromId === chatId && x.chatType === chatType)
            if(oldArray && oldArray[0]){
                let old = oldArray[0]
                if(!message.fromName){
                    message.fromName = old.fromName
                }
                if(!message.fromAvatar){
                    message.fromAvatar = old.fromAvatar
                }
                if(!message.context){
                    message.context = old.context
                }
                if(!message.notReadCount){
                    message.notReadCount = old.notReadCount
                }else {
                    message.notReadCount = old.notReadCount ? old.notReadCount +  message.notReadCount :  message.notReadCount
                }
            }

            //把之前的消息过滤掉
            state.chatList = state.chatList.filter(x=>!(x.fromId === chatId && x.chatType === chatType))
            //将新消息加入
            state.chatList.unshift(message)
            //localStorage.setItem(this.$store.state.system.user.id + '-'+ "chat_chatList", JSON.stringify(state.chatList))
        },
        /**
         * 将消息添加到聊天记录MAP里面,逆序
         * @param state
         * @param key 聊天人的id-聊天人的类型
         * @param message 消息
         */
        addMessageToMap(state,[key,message]){
            let map = state.messageMap;
            let array =  map[key]
            //如果消息来自当前聊天的对象，那么消息设置为已读
            let isRead = state.currentChatUser.fromId === message.fromId  && state.currentChatUser.chatType === message.chatType
            message.state = isRead ? 1 : message.state
            if(message.state === 0){
                state.notReadMessageMap ++
            }
            if(array && array.length > 0){
                //判断是否重复添加(不存在id或者id不等于上一条信息的id
                if(!message.id || array[0].id !== message.id){
                    array.unshift(message)
                }
            } else {
                Vue.set(state.messageMap,key,[message])
                //state.messageMap[key] = [message]
            }
        },
        /**
         *
         * @param state
         * @param key
         * @param messageList
         */
        addMessageListToMap(state,[key,messageList]){
            let array =  state.messageMap[key]
            if(array && array.length > 0){
                array.push(...messageList)
            } else {
                Vue.set(state.messageMap,key,messageList)
            }
            /*   messageList.sort((a,b)=>{
                   return moment(a.createTime).isAfter(moment(b.createTime)) ? 1 : -1
            })*/
        }
    },
    state:{
        /**
         * key: chatId-chatType
         * value: messageVO
         */
        messageMap:  {},
        /**
         * key:  chatId-chatType
         * value: 最久未读的记录的id
         * 用于获取聊天记录的时候，过滤掉已经获取的未读消息
         */
        notReadMessageMap:{},
        //聊天列表
        chatList: [],
        //消息列表中当前聊天的对象
        currentChatUser:{fromId:null},
        notReadMessageCount:0,
    }
}
