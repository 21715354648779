import request from "@/axios/request";

export default {


    getLoginUserInfo:()=>{
        return request.getRequest('/user/public/info/self')
    },
    getAllPublicUser:(data)=>{
        return request.postRequest('/user/public/all', data)
    },
    getUserInfo:(params)=>{
        return request.getRequest('/user/public/one', params)
    },
    getUserConfig:(params)=>{
        return request.getRequest('/config/public/get', params)
    },
    updateUserInfo:(data)=>{
        return request.postRequest('/user/user/update/info',data)
    },
    userBindEmail:(data)=>{
            return request.postRequest('/user/user/bind/email', data)
    },
    userUpdatePassword:(data)=>{
        return request.postRequest('/user/user/update/password', data)
    },
    regist(data){
        return request.postRequest('/user/public/regist',data)
    },
    login(data){
        return request.postRequest('/login',data)
    },
    getForgetEmailCode(data){
        return request.postRequest('/user/public/forget',data)
    },
    resetPassword(data){
        return request.postRequest('/user/public/reset',data)
    },
    updateAvatar(data){
        return request.postRequest('/user/user/update/avatar',data)
    },
    updateSignature(data){
        return request.postRequest('/config/user/update/signature',data)
    },
    updateBackGround(data){
        return request.postRequest('/config/user/update/background',data)
    },
    updateIntroduction(data){
        return request.postRequest('/config/user/update/introduction',data)
    }


}