
let ws;
//重连锁
let lockReconnect = false
let active_close = false
//let ws_url = 'ws://localhost:8082/ws/ptChat'
let ws_url = 'wss://sqdpt.top/ws/ptChat'

let heartCheck = {
    timeout: 1000 * 50,        //50秒发一次心跳
    timeoutObj: null,
    serverTimeoutObj: null,
    reset: function(){
        clearTimeout(this.timeoutObj);
        clearTimeout(this.serverTimeoutObj);
        return this;
    },
    start: function(){
        let self = this;
        this.timeoutObj = setTimeout(function(){
            ws.send("ping");
            self.serverTimeoutObj = setTimeout(function(){
                if(canConnect(ws)){
                    ws.close();
                }
            }, self.timeout)
        }, this.timeout)
    }

}

function connect(token){
    if(token && canConnect(ws)){
        console.log("[PtChat] 正在尝试连接websocket...")
        try {
            ws = new WebSocket(ws_url,[token.split(" ")[1]])
            initEventHandle(token)
        }catch (e){
            reconnect(token)
        }
    }
}
let outHandleWsOpen
let outHandleWsClose
let outHandleWsError
let outHandleWsMessage
function initEventHandle(token) {
    ws.onclose = function (e) {
        console.log('[PtChat] websocket 关闭: ' + e.code + ' ' + e.reason + ' ' + e.wasClean,e)
        reconnect(token);
        if(outHandleWsClose){
            outHandleWsClose(e)
        }
    };
    ws.onerror = function (e) {
        console.log('[PtChat] websocket 错误: ' + e.code + ' ' + e.reason + ' ' + e.wasClean,e)
        reconnect(token);
        if(outHandleWsError){
            outHandleWsError(e)
        }
    };
    ws.onopen = function (e) {
        console.log("[PtChat] websocket连接成功!")
        heartCheck.reset().start();      //心跳检测重置
        if(outHandleWsOpen){
            outHandleWsOpen(e)
        }
    };
    ws.onmessage = function (e) {    //如果获取到消息，心跳检测重置
        heartCheck.reset().start();      //拿到任何消息都说明当前连接是正常的
        if(e.data !== 'pong'){
            if(outHandleWsMessage){
                outHandleWsMessage(e)
            }
        }
    };
}

/**
 * 是否能连接
 * @param obj
 * @returns {boolean}
 */
function canConnect(obj){
   if(obj){
      let state =  obj.readyState
       return state === state.CLOSED || state === state.CLOSING;
   }
   return true
}



function bindWsOpen(handleWsOpen){
    outHandleWsOpen = handleWsOpen
}

function bindWsClose(handleWsClose){
    outHandleWsClose = handleWsClose
}

function bindWsError(handleWsError){
    outHandleWsError = handleWsError
}

function bindWsMessage(handleWsMessage){
    outHandleWsMessage = handleWsMessage
}

function sendMessage(message){
    if(message && message.context.trim().length > 0){
        ws.send(JSON.stringify(message))
    }
}

function close(){
    if(ws){
         ws.close()
    }
}
/**
 * 重写连接
 * @param token
 */
function reconnect(token) {
    if(lockReconnect) return;
    if(active_close) return;
    lockReconnect = true;
    setTimeout(function () {
        connect(token);
        lockReconnect = false;
    }, 2000);
}

/**
 * 关闭浏览器的时候，断开连接
 */
window.onbeforeunload = function() {
    if (ws && !canConnect(ws)) {
        active_close = true
        ws.close();
    }
}




export default {
    connect,
    bindWsOpen,
    bindWsClose,
    bindWsError,
    bindWsMessage,
    sendMessage,
    close
}