import Vue from "vue";
import Vuex from 'vuex'
Vue.use(Vuex)
import system from "@/store/modules/system";
import plan from "@/store/modules/plan";
import blog from "@/store/modules/blog";
import comment from "@/store/modules/comment";
import menu from "@/store/modules/menu";
import setting from "@/store/modules/setting";
import chat from "@/store/modules/chat"

export default new Vuex.Store({
        modules:{
            system,
            plan,
            blog,
            comment,
            menu,
            setting,
            chat
        }
})

