import request from "@/axios/request";

export default {

    uploadSingleFile(formData,url) {
        if(url){
            return request.postRequest(url,formData)
        }else {
            return request.postRequest('/file/user/upload/single',formData)
        }
    },
    downloadFile(params,type,progress) {
        return request.getRequestWithResponseType('/file/user/upload/download',params,type,progress)
    },
    downloadMinioFile(params,type,progress) {
        return request.getRequestWithResponseType('/minio/user/download/self',params,type,progress)
    },
    mergeFile(data){
        return request.postRequest('/minio/user/merge',data)
    },
    getPanFilePage(data){
        return request.postRequest("/file/user/page",data)
    },
    getPanMinioFilePage(data){
        return request.getRequest("/minio/user/page",data)
    },
    deleteFile(params){
        return request.deleteRequest('/file/user/upload/delete',params)
    },
    initUpload(data){
        return request.postRequest("/minio/user/init",data)
    },
    minioUploadSingle(formData){
        return request.postRequest("/minio/user/upload/single",formData)
    },
    minioUploadChunk(data){
        return request.postRequest("/minio/user/upload/chunk",data)
    },
    getUploadChunkUrl(){
        return request.baseUrl + "minio/user/upload/chunk"
    },
    getUploadSingleUrl(){
        return request.baseUrl + "minio/user/upload/single"
    },
    getUploadCheckUrl(){
        return request.baseUrl + "minio/user/upload/check"
    }



}