<template>
  <div id="app" >
    <router-view ></router-view>
    <a-back-top v-if="showToTop">
      <div  class="toTop">
        <a-icon  type="vertical-align-top" />
      </div>
    </a-back-top>
      <meting-js
          id="6742056437"
          fixed="true"
          server="netease"
          type="playlist">
      </meting-js>
    <a-modal
        :width="720"
        :visible="showPanPage"
        :bodyStyle="{padding:'5px 15px 5px 15px'}"
        :footer="null"
        @cancel="cancel"
        title="PT网盘：飞一般的感觉">
      <pan-page></pan-page>
    </a-modal>
    <div class="body-mask">
      <div v-for="item in  50" :key="item" style="line-height: 10px">
        <p>{{user.nickname?user.nickname:'PT的小破站'}}</p>
      </div>
    </div>
  </div>
</template>

<script>

import systemApi from "@/axios/api/systemApi"
import userApi from "@/axios/api/userApi"
import PHeader from "@/components/template/PHeader";
import PFooter from "@/components/template/PFooter";
import PanPage from "@/components/PanPage";
import SvgIcon from "@/components/template/common/SvgIcon";
import moment from "moment/moment";
import initSysImg from "@/mixin/initSysImg";
import watcherApi from "@/axios/api/watcherApi";
import socketUtil from "@/utils/websocket"
import chatApi from "@/axios/api/chatApi";
import { notification } from "ant-design-vue"
export default {
  name: 'App',
  components: {SvgIcon, PanPage, PHeader, PFooter},
  created() {
    this.initUserInfo()
    this.initSys()
    this.removeIndexLoding()
    this.addWatcher()
    this.initNotReadChatMessageCount()
    socketUtil.connect(this.token)
    socketUtil.bindWsOpen(this.openWebSocket)
    socketUtil.bindWsClose(this.closeWebSocket)
    socketUtil.bindWsError(this.errorWebSocket)
    socketUtil.bindWsMessage(this.messageWebSocket)
    document.addEventListener('visibilitychange', function () {
      let hiddenTime
      if (document.visibilityState === 'hidden') {
          //记录页面隐藏时间
           hiddenTime = new Date().getTime()
      } else {
       if(hiddenTime){
         let visibleTime = new Date().getTime();
         //页面再次可见的时间-隐藏时间>10S,重连
         if((visibleTime - hiddenTime) / 1000 > 10){
           // 主动关闭连接
           socketUtil.close()
           // 1.5S后重连 因为断开需要时间，防止连接早已关闭了
           setTimeout(function(){
             socketUtil.connect(this.token)
           },1500);
         }
       }
      }
    })

  },
  data(){
    return{
      showPanPage:false
    }
  },
  computed:{
    showHead(){
        let path = this.$route.path
        return path !== '/login' && path !== '/regist' && path !== '/forget'
    },
    showFooter(){
      let path = this.$route.path
      return path === '/'
    },
    showToTop(){
      let path = this.$route.path
      return path !== '/login' && path !== '/regist' && path !== '/forget'
    },
    currentPath(){
       return this.$store.state.menu.currentMenu
    },
    token(){
       return this.$store.state.system.token
    },
    user(){
      return this.$store.state.system.user
    },
    rememberToken(){
      return this.$store.state.system.remember
    },
    sysBack(){
      return this.sysConfig.background
    },
    canLoadBack(){
      let sysConfig = this.sysConfig
      if(sysConfig && sysConfig.loadTime){
        let ago = new Date() - 10 * 60 * 1000
        return  moment(sysConfig.loadTime).isBefore(moment(ago))
      }else {
        return true
      }
    },
    canLoadUser(){
      if(this.token){
          if(this.user && this.user.lastLoadTime){
              let ago = new Date() - 10 * 60 * 1000
              return  moment(this.user.lastLoadTime).isBefore(moment(ago))
          }else {
            return true
          }
      }
      return this.rememberToken
    },
    lastWatcherTime(){
      return this.$store.state.system.lastWatcherTime
    },
    canAddWatcher(){
      if(this.lastWatcherTime){
        let ago = new Date() - 30 * 60 * 1000
        return  moment(this.lastWatcherTime).isBefore(moment(ago))
      }else {
        return true
      }
    }
  },
  mixins:[initSysImg],
  watch:{
    currentPath(value){
      this.showPanPage = value === '/pan';
    },
    sysBack(value){
      document.querySelector('body').setAttribute('style', "background-image: url("+value+");")
    }
  },
  methods:{
    initNotReadChatMessageCount(){
      if(!this.token){
        return
      }
      chatApi.getNotReadMessageCount().then(resp=>{
          if(resp.data.success){
              this.$store.dispatch('chat/setNotReadMessageCount',resp.data.data)
          }
      })
    },
    openWebSocket(e){
      notification.success({
        message:'【PtChat】已连接',
        description:'可以愉快的聊天啦！',
      })
    },
    closeWebSocket(e){
      notification.warning({
        message:'【PtChat】断开连接',
        description:  e.code + ' ' + e.reason,
      })
    },
    errorWebSocket(e){
      notification.error({
        message:'【PtChat】连接失败',
        description:  e.code + ' ' + e.reason,
      })
    },
    messageWebSocket(e){
        let msg = e.data
        if(msg){
          let data  =  JSON.parse(msg)
          this.$store.dispatch('chat/getMessage',data)
          if(this.$router.currentRoute.name !== 'PtChatPage'){
            notification.info({
            message: data.fromName ,
            description: data.context ,
            duration:null,
            onClick: ()=>{
              notification.destroy()
              this.$router.push({
                path:'/chat'
              })
            }
          })
          }
        }

    },
    addWatcher(){
      if(this.canAddWatcher){
        watcherApi.addWatcher().then(resp=>{
          if(resp.data.success){
              this.$store.dispatch('system/save_last_watcher_time',new Date())
          }
        })
      }
    },
    removeIndexLoding(){
      try {
        document.body.removeChild(document.getElementById('appLoading'))
        document.getElementById('app').style.display = 'block';
      } catch (e) {
      }
    },
    initSys(){
      if( this.canLoadBack  ){
        systemApi.getSystemInfo().then(resp=>{
          let sys = resp.data.data
          this.$store.dispatch('system/save_sys_config',sys)
        })
      }
      document.querySelector('body').setAttribute('style', "background-image: url("+this.sysBack+");")
    },
    cancel(){
      this.$store.dispatch("menu/submit_menu",'/')
    },
    initUserInfo(){
      if(this.canLoadUser){
        userApi.getLoginUserInfo().then(resp=>{
          if(resp.data.success){
            let user =  resp.data.data
            if(user !== null){
              this.$store.dispatch("system/save_user",user)
            }
          }
        })
      }
    }
  }
}
</script>

<style>
@import "assets/css/global.css";
body{
  background-color: white;
  background-repeat: no-repeat;
  background-position: left;
  background-size: cover;
  background-attachment: fixed;
}


.toTop{
  height: 40px;
  width: 40px;
  line-height: 40px;
  border-radius: 20px;
  background-color: rgba(16, 136, 233, 0.38);
  color: #fff;
  text-align: center;
  font-size: 20px;

}

.body-mask{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.15;
  width: 100%;
  pointer-events: none;
  display: flex;
  flex-wrap: wrap;
  z-index: -999;
}
.body-mask div{
  margin: 80px;
  transform: rotate(-30deg);
}

.aplayer.aplayer-withlist.aplayer-fixed.aplayer-narrow,
.aplayer.aplayer-withlist.aplayer-fixed.aplayer-narrow .aplayer-body {
  left: -66px !important;
}

.aplayer.aplayer-withlist.aplayer-fixed.aplayer-narrow:hover .aplayer-body {
  left: 0 !important;
}
.aplayer.aplayer-fixed .aplayer-lrc{
  display: none;
}


</style>
