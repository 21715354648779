<template>
  <a-spin :spinning="showProgress" :tip="progress">
     <a-list-item  class="pan-item">
       <a-list-item-meta >
              <span slot="description" style="font-size: 12px">
    <!--           <span>类型：{{item.type}} | </span>-->
               <span style="padding-right: 5px">大小：{{fileMb(item.fileSize)}}</span>
               <span> 创建时间：{{item.createTime}}</span>
              </span>
         <span slot="title" class="text-ellipsis pan-item-name" >{{item.fileName}}</span>
         <svg-icon  slot="avatar"
                    :hover="true"
                    :iconClass="item.type !=='folder' ? '#icon-wenjian' : '#icon-wenjianjia'"
                    style="margin: 0 5px 5px 0;"
                    size="large"></svg-icon>
       </a-list-item-meta>
       <a v-if="item.type ==='folder'" slot="actions">打开</a>
       <a v-else slot="actions" @click="downloadFile(item)">下载</a>
       <a slot="actions" @click="deleteFile(item.id)">删除</a>
     </a-list-item>
  </a-spin>
</template>

<script>
import fileApi from "@/axios/api/fileApi";
import SvgIcon from "@/components/template/common/SvgIcon";
export default {
  name: "PanItem",
  components: {SvgIcon},
  props:{
    item:{
      type:Object,
      required:true
    },
    deleteFile:{
      type:Function,
      required: true
    }
  },
  data(){
    return{
      progress:'下载中',
      showProgress:false,
    }
  },
  methods:{
    fileMb(fileSize){
      if (!fileSize){
        return 0 +  "bit"
      }else if(fileSize < 1024 ){
        return fileSize + 'B'
      }else if(fileSize < 1024 * 1024){
        return (fileSize / 1024).toFixed(2) + 'KB'
      }else if(fileSize < 1024 * 1024 * 1024){
        return (fileSize / 1024 / 1024).toFixed(2) + 'MB'
      }else if(fileSize < 1024 * 1024 * 1024 * 1024){
        return (fileSize / 1024 / 1024 /1024).toFixed(2)+ 'GB'
      }
    },
    fileDownloadProgress(e){
      let load = e.loaded
      let total = e.total
      console.log("fileDownloadProgress",load,total,e)
      if(total == 0 || load == 0){
        this.progress =  "0%"
      }
      this.progress =this.fileMb(load) + ' ('+ Math.floor((load/total) *100) +'%）'
    },
    downloadFile(item){
      if(this.showProgress === false){
        this.showProgress = true
        fileApi.downloadMinioFile({id: item.id},'blob',(e)=>{
          this.fileDownloadProgress(e)
        }).then(resp=>{
              if(resp.status === 200){
                //创建 blob 对象
                const blob = new Blob([resp.data]);
                //创建一个唯一的 URL
                const url = URL.createObjectURL(blob);
                //创建一个a标签
                const a = document.createElement("a");
                //将下载地址赋值给herf属性
                a.href = url;
                //设置下载文件的名字
                let fileName =  resp.headers['content-disposition'].split('filename=')[1]
                a.download = fileName;
                a.style.display = 'none';// 将a标签设置为隐藏
                a.click();
                URL.revokeObjectURL(a.href) // 下载完成释放URL 对象
                this.$message.success("下载成功")
              }
              else {
                this.$message.error("下载错误")
              }
              this.showProgress = false
            }
        )
      }
    }
  }
}
</script>

<style scoped>
.pan-item{
  padding: 10px 5px 10px 5px;
}
.pan-item:hover{
  background-color: rgba(229, 243, 255);
}
/*手机*/
@media screen and (max-device-width: 992px){
.pan-item-name{
  max-width: 200px;
}
}
/*电脑*/
@media screen and (min-device-width: 993px){
  .pan-item-name{
    max-width: 600px;
  }

}
</style>