export default {
   methods:{
       goPath(path) {
           if(path !== '/pan' ){
               if(path === '/userInfo'){
                   this.$router.push({
                       path:path,
                       query:{id:this.$store.state.system.user.id}}
                   )
               }else {
                   this.$router.push(path)
               }
               this.$store.dispatch('menu/submit_menu',path)
           }else{
               if(this.$store.state.system.token){
                   this.$store.dispatch('menu/submit_menu',path)
               }else {
                   this.$message.error("该功能需要登录")
               }
           }
       }
   }
}